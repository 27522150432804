$black: #000000;
$gray: #eff2f5;
$blue: #0068dc; // #0450F2 (for ROS)
$blue_2: #0db9dd;
$border_gray: rgba(107, 120, 134, 0.2);
$input_border_gray: rgba(107, 120, 134, 0.5);
$light_gray: #38506a;
$input_focus_border_blue: #4096ff;
$light_blue: #003876;
$light_blue_2: rgba(0, 143, 255, 0.1);
$border_light_gray: $gray;
$white: #fff;
$dark_gray: #646363;

$green: rgba(9, 173, 5, 1);
$green_light: rgba(9, 173, 5, 0.2);

$red: #ff3232;

$raspberry: rgba(243, 74, 104, 1);
$raspberry_light: rgba(243, 74, 104, 0.2);

$gold: #f8b54c;
$gold_2: #ffcc66;

$orange: rgba(255, 142, 0, 1);
$orange_light: rgba(255, 142, 0, 0.2);

:export {
  black: $black;
  gray: $gray;
  blue: $blue;
  blue_2: $blue_2;
  border_gray: $border_gray;
  light_blue: $light_blue;
  light_blue_2: $light_blue_2;
  light_gray: $light_gray;
  input_border_gray: $input_border_gray;
  white: $white;
  dark_gray: $dark_gray;
  green: $green;
  red: $red;
  gold: $gold;
  gold_2: $gold_2;
  orange: $orange;
}
