@import '@/styles/colors.module';

.wrapper {
  background: $gray;
  position: relative;

  .header {
    height: 96px;
    margin: 0 64px;
    padding: 15px 0;
    display: flex;
    gap: 64px;

    .logo {
      flex-shrink: 0;
    }

    &GPB {
      align-items: center;
      gap: 29px;
      padding: 24px 0;

      .divider {
        height: 48px;
        margin-inline: 0 !important;
        border-color: $border_gray;
      }
    }

    &PIRSON {
      gap: 69px;
      padding: 20px 0;

      .navigation {
        margin-top: 1px;
      }
    }

    .actionsWrapper {
      margin-left: auto;
    }
  }
}
